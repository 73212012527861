interface String {
    toCamel(): string;
}

String.prototype.toCamel = function(): string {
    return this.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
};
