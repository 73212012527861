interface Blob {
    ntArrayBuffer(): Promise<ArrayBuffer>;
}

Blob.prototype.ntArrayBuffer = function(): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
            reader.removeAllListeners();
            resolve(event.target.result as ArrayBuffer);
        });

        reader.readAsArrayBuffer(this);
    });
}