interface String {
    lpad(padString: string, length: number): string;
}

String.prototype.lpad = function(padString: string, length: number): string {
    let str = this;
    while (str.length < length){
        str = padString + str;
    }
    return str;
}
