// declare global {
interface Array<T> {
    // tslint:disable-next-line: ban-types
    sortBy: (by: string, reverse?: boolean) => Array<T>;
}
// }

Array.prototype.sortBy = function(by, reverse = false) {
    const desc = (dir) => Math.abs(dir) - dir - (1 * Math.abs(dir));
    const compare = (a, b) => {
        const sort  = (a[by] > b[by] ? 1 : (a[by] < b[by] ? -1 : 0))
        return reverse ? desc(sort) : sort;
    }

    return this.sort(compare);
};