// declare global {
interface ObjectConstructor {
    // tslint:disable-next-line: ban-types
    filter: (obj, predicate) => any;
}
// }

Object.filter = (obj, predicate) => {
    const result = {};
    let key;

    for (key in obj) {
        if (obj.hasOwnProperty(key) && predicate(obj[key], key)) {
            result[key] = obj[key];
        }
    }

    return result;
};